import React, { useState } from "react";
import hires_1 from "./images/hires_1.png";
import hires_2 from "./images/hires_2.png";
import hires_3 from "./images/hires_3.png";
import hires_4 from "./images/hires_4.png";
import hires_5 from "./images/hires_5.png";
import hires_6 from "./images/hires_6.png";
import hires_7 from "./images/hires_7.png";
import hires_8 from "./images/hires_8.png";
import "./App.css";
import Slideshow from "./Slideshow"; // Import Slideshow from the Slideshow module

const apiBaseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL // Production environment
    : "http://localhost:3001/api"; // Local development environment

function App() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [story, setStory] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(true); // State to track form completeness

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleStoryChange = (event) => {
    setStory(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if name, email, and story are all filled
    if (!name || !email || !story) {
      setIsFormComplete(false);
      return;
    }

    setIsFormComplete(true); // Reset form completeness

    try {
      const response = await fetch(`${apiBaseUrl}/submit-finding`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, story }),
      });

      if (response.ok) {
        console.log("Story submitted successfully");
        setName("");
        setEmail("");
        setStory("");
      } else {
        console.error("Failed to submit story");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="App">
      <Slideshow
        images={[
          hires_1,
          hires_2,
          hires_3,
          hires_4,
          hires_5,
          hires_6,
          hires_7,
          hires_8,
        ]}
      />
      <div className="overlay">
        <header className="App-header">
          <p>
            Welcome to DIR.lol, an open book about how to do things "right" by
            members of{" "}
            <a
              className="App-link"
              href="https://labrat.camp"
              target="_blank"
              rel="noreferrer noopener"
            >
              labrat.camp
            </a>
          </p>

          <h2>Submit Your Story</h2>
          {!isFormComplete && (
            <p style={{ color: "red" }}>Please fill out all fields.</p>
          )}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={name}
              onChange={handleNameChange}
              placeholder="Your name"
              required
            />
            <br />
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Your email address"
              required
            />
            <br />
            <textarea
              value={story}
              onChange={handleStoryChange}
              placeholder="Share your story..."
              rows={5}
              cols={40}
            />
            <br />
            <button type="submit">Submit</button>
          </form>
        </header>
      </div>
    </div>
  );
}

export default App;
